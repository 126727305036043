.menu-button{
    color:black;
    border: none;
    border-radius: 25px;
    padding: 25px 200px;
    margin: 10px;
    cursor: pointer;
    font-size:1.8rem;
    font-weight: 300;
    letter-spacing: 0.1cap;
    box-shadow: 0 10px 10px 0 rgba(0,0,0,0.2);
    height:75px;
    width:400px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap; /* Ajoutez cette ligne */
}

@media screen and (max-width: 768px){
    .menu-button{
        padding: 25px 100px;
        width: 300px;
    }
    
}

.menu-button.primary{
    background-color: #e03243;
    color: #feffe6;
}

.menu-button.primary:hover{
    background-color: #feffe6;
    border: solid 3px #e03243;
    color: #e03243;
}

.menu-button.secondary{
    background-color: #72a855;
    color:black !important;
}

.menu-button.secondary:hover{
    background-color: #feffe6;
    border: solid 3px #72a855;
    color: #72a855 !important;
}

.menu-button.tertiary{
    background-color: #dbaf1d;
    color:black !important;
}
.menu-button.tertiary:hover{
    background-color: #feffe6;
    color:#dbaf1d !important;
    border: solid 3px #dbaf1d;
}

.menu-button.rules{
    background-color: #feffe6;
    border: solid 3px black;
    color:black !important;
}
.menu-button.rules:hover{
    background-color: #c6c7b4;
    border: solid 3px black;
    color:black !important;
}
.question-mark{
    font-weight:bold;
}