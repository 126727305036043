.keyboard-key{
    height:2.6rem;
    display:flex;
    justify-content: center;
    align-items: center;
    flex:1;
    border-width:1px;
    border: solid white;
    font-size:1.6rem;
    font-weight:bold;
    background-color: aqua;
    box-sizing: content-box;
    color:white;
    background-color:rgb(83, 83, 188);
    user-select: none;
}

.flex-2{
    flex:2;
}

.keyboard-key:hover{
    cursor: pointer;
    background-color: rgb(63, 63, 182);
}

@media screen and (max-width: 600px) {
    .keyboard-key{
        padding:5px;
    }
}

.correct-key,.keyboard-key.correct-key:hover{
    background-color: red;
}

.misplaced-key, .keyboard-key.misplaced-key:hover{
    background-color: rgb(242, 180, 88);
}

.wrong-key, .keyboard-key.wrong-key:hover{
    background-color:rgba(83, 83, 188, 0.227);
}

.keyboard-key.return{
    background-color: rgb(224, 94, 94);
}
.keyboard-key.return:hover{
    background-color: rgb(173, 74, 74);
}

.keyboard-key.enter{
    background-color: #72a855;
}
.keyboard-key.enter:hover{
    background-color: #486936;
}