body{
    max-width:100vw;
}

.grid-container{
    width:40vw;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.grid-row{
    display:flex;
    justify-content: center;
    align-items: center;
}

.grid-item{
    height:2.3vw;
    width: 2.3vw;
    border: white solid 2px;
    font-size:1.6rem;
    font-weight:bold;
    color:white;
    display:flex;
    justify-content: center;
    align-items: center;
    padding:5px;
    margin:0;
    background-color:rgb(83, 83, 188);
    box-sizing: content-box;
    user-select: none;
}

@media screen and (max-width: 600px) {
    .grid-item{
        height:1.5rem;
        width: 1.5rem;
    }
}

.grid-row > .grid-item.letter-input{
    background-color:rgb(107, 107, 234);
}

.grid-row.completed > .grid-item.letter-input{
    background-color:rgb(83, 83, 188);
}

.grid-row.finished > .grid-item.letter-input{
    background-color:red;
}
.grid-row.completed > .grid-item.letter-input.correct{
    background-color: red;
}

.grid-row.completed > .grid-item.letter-input.misplaced{
    background-color: rgb(242, 180, 88);
}

.flex-1{
    flex:1;
}

.flex-2{
    flex:2;
}

.confettis{
    position:absolute;
    width:100vw;
    height:100vh;
    display:none;
}

.btn-home{
    background:none;
    border:none;
    background-color:#72a855;
    padding:10px;
    border-radius: 25px;
    box-shadow: 0 10px 10px 0 rgba(0,0,0,0.2);
}

.btn-home:hover{
    background-color:#619149;
}

.spinner-container{
    position:absolute;
    background-color: rgba(255, 255, 255, 0.404);
    height:100%;
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    font-size:15rem;
}