.word-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #feffe6;
    padding: 20px;
    border-radius: 25px;
    box-shadow: 0 10px 10px 0 rgba(0,0,0,0.2);
}

.word{
    font-size:1.8rem;
    font-weight: bold;
    letter-spacing: 0.1cap;
}

.indicateur-container{
    height:20vh;

}
.indicateur{
    padding:15px;
    border-radius: 25px;
    font-size:1.3rem;
    font-weight:bold;
    background-color: #feffe6;
    box-shadow: 0 10px 10px 0 rgba(0,0,0,0.2);
}

.btn-container{
    border:none;
    border-radius:100%;
    width:80px;
    height: 80px;
}


.btn-container.validate{
    background-color: #72a855;
}

.btn-container.pass{
    background-color: rgb(227, 105, 117);
}

.btn-container.pass:hover{
    background-color: rgb(227, 70, 85);
}

.reveal{
    position:absolute;
    left:90%;
    border:none;
    background-color: #feffe6;
}


@media screen and (max-width: 768px){
    .reveal{
        left:80%;
    }
}

.btn-validate{
    background-color: #72a855 !important;
    color:white !important;
}
.btn-validate:hover{
    background-color: #567f40 !important;
}

.modal-footer{
    border-top:none !important;
}

.modal-header{
    border-bottom:none !important;
}