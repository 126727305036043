/* background : #feffe6 */
/* primary : #72a855 */
/* secondary : #a4b976 */
/* accent : #E03244 */
/* text : #050315 */

.game-title{
  font-size: 4rem;
  color: #050315;
  text-align: center;
  margin:0;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

@media screen and (max-width: 600px){
  .game-title{
    font-size: 2rem;
  }
}
html{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important;
}

body{
  color: #050315;
  background-color: #feffe688 !important;
  display:flex;
  justify-content:center;
  align-items: center;
  max-width:100vw;
  max-height:100vh;
}

section{
  height:100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 800px){
  section{
    height:80vh;
    min-height: 80vh;
  }
  
}

.button-container{
  width:17vw;
  justify-content: space-between;
  height:13vw;
}

.home-page{
  height:100%;
  display:flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}